@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .svg {
        @apply fill-primary dark:fill-white cursor-pointer w-5 h-5
    }

    .btn-primary {
        @apply my-1 w-full py-2 bg-white dark:bg-primary rounded-xl text-[20px] dark:text-white
    }

    .btn-secondary {
        @apply bg-primary my-1 w-full py-2 px-6 text-white dark:bg-primary rounded-xl text-[20px] w-fit
    }

    .btn-toggle {
        @apply bg-white dark:bg-primary flex p-2 border-primary dark:border-white border-2 rounded-full
    }

    .game-item {
        @apply h-20 flex rounded-xl p-2 text-[1.5vw]
    }

    .fix-item {
        @apply bg-fixInput text-white dark:text-black dark:bg-white  dark:border-2 dark:border-[#C9C9C9] relative
    }

    .move-item {
        @apply bg-movingInput dark:bg-darkMovingInput border-2 border-[#c9c9c9] dark:border-[#707070] text-[#878787] dark:text-white
    }
}

@layer base {
    * {
        @apply font-['Myriad_Pro'] font-semibold
    }

    input {
        @apply bg-input dark:bg-darkInput w-full py-2 rounded-xl mb-5 text-[#fff] text-center
    }
}
.fix-item:after {
    content: url("data:image/svg+xml,%3Csvg id='Component_15_86' data-name='Component 15 – 86' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='0 0 33 33'%3E%3Cdefs%3E%3Cfilter id='Union_1'%3E%3CfeOffset dx='1' dy='1' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161' result='color'/%3E%3CfeComposite operator='out' in='SourceGraphic' in2='blur'/%3E%3CfeComposite operator='in' in='color'/%3E%3CfeComposite operator='in' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg data-type='innerShadowGroup'%3E%3Cpath id='Union_1-2' data-name='Union 1' d='M-2266.373-3622v-10.627H-2277v-11.186h10.627V-3655h11.187v11.187H-2244v11.186h-11.186V-3622Z' transform='translate(2277 3655)' fill='%23cfcfcf'/%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Union_1)'%3E%3Cpath id='Union_1-3' data-name='Union 1' d='M-2266.373-3622v-10.627H-2277v-11.186h10.627V-3655h11.187v11.187H-2244v11.186h-11.186V-3622Z' transform='translate(2277 3655)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(160%, -50%);
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('assets/fonts/MyriadPro-BlackSemiCn.woff2') format('woff2'),
    url('assets/fonts/MyriadPro-BlackSemiCn.woff') format('woff'),
    url('assets/fonts/MyriadPro-BlackSemiCn.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('assets/fonts/MyriadPro-Bold.woff2') format('woff2'),
    url('assets/fonts/MyriadPro-Bold.woff') format('woff'),
    url('assets/fonts/MyriadPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('assets/fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('assets/fonts/MyriadPro-Regular.woff') format('woff'),
    url('assets/fonts/MyriadPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('assets/fonts/MyriadPro-Semibold.woff2') format('woff2'),
    url('assets/fonts/MyriadPro-Semibold.woff') format('woff'),
    url('assets/fonts/MyriadPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('assets/fonts/MyriadPro-Cond.woff2') format('woff2'),
    url('assets/fonts/MyriadPro-Cond.woff') format('woff'),
    url('assets/fonts/MyriadPro-Cond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('assets/fonts/MyriadPro-It.woff2') format('woff2'),
    url('assets/fonts/MyriadPro-It.woff') format('woff'),
    url('assets/fonts/MyriadPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Good Brush';
    src: url('assets/fonts/Good Brush.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


